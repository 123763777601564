@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'geomanistregular';
  src: url('./assets/geomanist-regular-webfont.eot');
  src: url('./assets/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/geomanist-regular-webfont.woff2') format('woff2'),
       url('./assets/geomanist-regular-webfont.woff') format('woff'),
       url('./assets/geomanist-regular-webfont.ttf') format('truetype'),
       url('./assets/geomanist-regular-webfont.svg#geomanistregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

html{
  font-size : 100%;
  @apply scroll-smooth;
}

html::-webkit-scrollbar{
  @apply  w-1 rounded-sm bg-gray-50;
}
html::-webkit-scrollbar-thumb{
  @apply absolute w-1 rounded-md bg-slate-300;
}
*, *::after{
  @apply transition-all duration-200 appearance-none outline-none;
}

body {
  @apply bg-white
}
.tabs{

}

.tabs .headers-wrapper {
  @apply sticky z-10 top-24 bg-white;
}
.tabs .headers {
  @apply  flex border-b-2 border-b-secondary border-opacity-40 overflow-x-auto
}

.tabs .headers .header{
  @apply  cursor-pointer px-2 md:px-4 lg:px-8 py-4 flex items-center gap-x-4 text-secondary hover:bg-secondary hover:bg-opacity-10
}
.tabs .headers-wrapper .headers .header.active{
  @apply bg-secondary text-white;
}
.tabs .headers-wrapper .headers .header.active div:first-child {
  @apply bg-white bg-opacity-20;
}
.tabs .headers-wrapper .headers .header div:first-child{
  @apply w-12 h-12 flex items-center justify-center bg-secondary bg-opacity-20 rounded-full;
}
.tabs .headers-wrapper .headers .header div:last-child{
  @apply flex flex-col;
}
.tabs .headers-wrapper .headers .header div:last-child span:first-child{
  @apply text-lg font-semibold;
}
.tabs .headers-wrapper .headers .header div:last-child span:last-child{
  @apply text-sm whitespace-nowrap
}

.tabs .headers-wrapper .contents-header{
  @apply bg-secondary text-white grid grid-flow-col-dense py-3 px-4; 
}

.tabs .contents{

}


.tabs .contents .content{
  @apply hidden;
}
.tabs .contents .content.active{
  @apply block
}

.tabs .contents .content .row{
  @apply py-4 flex text-sm items-center ;
}
.tabs .contents .content .row:nth-child(odd){
  @apply bg-primary bg-opacity-10
}
.tabs .contents .content .row > div{
  @apply w-1/4
}
.tabs .contents .content .row .time{
  @apply pl-4 flex items-center gap-2
}
.tabs .contents .content .row .time i{
  @apply text-lg
}
.tabs .contents .content .row .speaker{
  @apply flex items-center gap-2;
}
.tabs .contents .content .row .speaker img{
  @apply w-10 h-10 object-cover rounded-full
}
.tabs .contents .content .row .speaker span{
  @apply font-medium
}